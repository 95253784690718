<!--
 * @Description: 全部任务项
 * @Author: ChenXueLin
 * @Date: 2021-10-14 08:59:17
 * @LastEditTime: 2022-11-25 15:54:09
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i class="e6-icon-history" title="历史记录" @click="toRecord"></i>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="itemId">
              <el-input
                v-model="searchForm.itemId"
                placeholder="工单ID"
                title="工单ID"
                maxlength="10"
                @input="
                  searchForm.itemId = searchForm.itemId.replace(/[^0-9-]+/, '')
                "
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="itemStatus">
              <e6-vr-select
                v-model="searchForm.itemStatus"
                :data="itemStatusList"
                placeholder="任务项状态"
                title="任务项状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneType">
              <e6-vr-select
                v-model="searchForm.sceneType"
                :data="sceneTypeList"
                placeholder="场景类型"
                title="场景类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskSecondClass">
              <e6-vr-select
                v-model="searchForm.taskSecondClass"
                :data="taskTypeList"
                placeholder="任务单类型"
                title="任务单类型"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="workNo">
              <el-input
                v-model="searchForm.workNo"
                placeholder="所属任务单号"
                title="所属任务单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="scheduleTaskId">
              <el-input
                v-model="searchForm.scheduleTaskId"
                placeholder="所属调度任务ID"
                title="所属调度任务ID"
                @input="
                  searchForm.scheduleTaskId = searchForm.scheduleTaskId.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="scheduleInfoId">
              <el-input
                v-model="searchForm.scheduleInfoId"
                placeholder="所属调度信息ID"
                title="所属调度信息ID"
                @input="
                  searchForm.scheduleInfoId = searchForm.scheduleInfoId.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerrpid">
              <e6-vr-select
                v-model="searchForm.engineerrpid"
                :data="engineerList"
                placeholder="工程师"
                title="工程师"
                clearable
                :props="{
                  id: 'employeeId',
                  label: 'employeeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerrpPhone">
              <el-input
                v-model="searchForm.engineerrpPhone"
                placeholder="工程师电话"
                title="工程师电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <input-select
                v-model="searchForm.corpName"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpName',
                  label: 'corpName'
                }"
              ></input-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="finishTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="完成时间"
                ref="effectiveDatePicker"
                v-model="searchForm.finishTime"
                title="完成时间"
                :picker-options="pickerOptions('searchForm.finishTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="完成时间（始）"
                end-placeholder="完成时间（止）"
              ></e6-date-picker>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div class="table-label" v-if="column.fieldName === 'label'">
                <span
                  v-for="(item, index) in row.label"
                  :key="index"
                  class="label-box"
                  >{{ item }}</span
                >
              </div>
              <span
                v-else-if="column.fieldName === 'status'"
                :class="[
                  'status',
                  row.status === 1
                    ? 'finish'
                    : row.status === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                已完成
              </span>
              <span v-else-if="column.fieldName === 'workNo'">
                <el-button type="text" @click="handleGoToDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else-if="column.fieldName === 'scheduleId'">
                <el-button type="text" @click="toSchedule(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else-if="column.fieldName === 'scheduleInfoId'">
                <el-button type="text" @click="toScheduleInfoDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { exportData } from "@/utils/download";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import {
  getAllTaskItemPage,
  findDownList,
  getEngineer,
  exportAllTaskItem
} from "@/api";
export default {
  name: "allActionItem",
  data() {
    return {
      loading: false,
      searchForm: {
        itemId: "", //工单ID
        itemStatus: "", //任务项状态
        sceneName: "", //场景名称
        sceneType: "", //场景类型
        workNo: "", //任务单号
        taskSecondClass: [], //任务单类型
        scheduleTaskId: "", //调度任务ID
        scheduleInfoId: "", //	调度信息ID
        corpName: "", //	客户名称
        engineerrpid: "", //	工程师ID
        engineerrpPhone: "", //	工程师电话
        finishTime: [],
        finishTimeStart: "", //完成时间开始
        finishTimeEnd: "", //	完成时间结束
        pageIndex: 1,
        pageSize: 20
      },
      taskTypeList: [], //工单类型
      itemStatusList: [], //任务项状态
      sceneTypeList: [], //场景类型
      corpList: [], //客户名称
      engineerList: [], //工程师下拉框
      total: 0,
      columnData: [
        {
          fieldName: "itemId",
          display: true,
          fieldLabel: "工单ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "itemStatusName",
          display: true,
          fieldLabel: "任务项状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "newSceneName",
          display: true,
          fieldLabel: "新场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneTypeName",
          display: true,
          fieldLabel: "场景类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskSecondClassName",
          display: true,
          fieldLabel: "任务单类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workNo",
          display: true,
          fieldLabel: "所属任务单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scheduleId",
          display: true,
          fieldLabel: "所属调度任务ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scheduleInfoId",
          display: true,
          fieldLabel: "所属调度信息ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerrpName",
          display: true,
          fieldLabel: "工程师",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerrpPhone",
          display: true,
          fieldLabel: "工程师电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workStartTime",
          display: true,
          fieldLabel: "作业时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishTime",
          display: true,
          fieldLabel: "完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [] // 表格数据
    };
  },
  mixins: [listPage, base, listPageReszie],
  watch: {
    // 完成时间
    "searchForm.finishTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.finishTime = this.searchForm.finishTime.reverse();
        }
        this.searchForm.finishTimeStart = val[0] || "";
        this.searchForm.finishTimeEnd = val[1] || "";
      }
    }
  },
  computed: {},

  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        let promiseList = [
          findDownList(["itemStatus", "sceneType", "taskSecondClass"]),
          getEngineer({})
        ];
        let [taskTypeRes, engineerRes] = await Promise.all(promiseList);
        //任务项状态
        this.itemStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.itemStatus"
        });
        //工单类型
        this.taskTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSecondClass"
        });
        //场景类型下拉框
        this.sceneTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.sceneType"
        });
        //获取工程师下拉框名称
        this.engineerList = this.getFreezeResponse(engineerRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //查询全部调度信息
    async queryList() {
      try {
        this.loading = true;
        if (!this.searchForm.scheduleInfoId)
          delete this.searchForm.scheduleInfoId;
        if (!this.searchForm.scheduleTaskId) {
          delete this.searchForm.scheduleTaskId;
        }
        if (!this.searchForm.itemId) delete this.searchForm.itemId;
        let res = await getAllTaskItemPage(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //导出任务项
    handleExport() {
      exportData(this, exportAllTaskItem);
    },
    // 日志
    toRecord() {
      this.routerPush({
        name: "taskOrderActiveRecord",
        params: {
          refresh: true
        }
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/ActionItemDetail",
          params: {
            refresh: true,
            itemId: row.itemId
          }
        });
      }
    },
    //创建任务单
    createTask() {
      this.routerPush({
        name: "taskListManage/addTaskOrder",
        params: {
          refresh: true
        }
      });
    },
    //去任务单详情
    handleGoToDetail(row) {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true,
          taskId: row.taskId
        }
      });
    },
    //去调度任务详情
    toSchedule(row) {
      this.routerPush({
        name: "taskListManage/schedulingTaskDetails",
        params: {
          refresh: true,
          scheduleId: row.scheduleId
        }
      });
    },
    //去调度信息详情
    toScheduleInfoDetail(row) {
      if (!row.scheduleInfoId) return;
      this.routerPush({
        name: "taskListManage/schedulingInfoDetails",
        params: {
          refresh: true,
          scheduleInfoId: row.scheduleInfoId,
          taskId: row.taskId
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.table-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .label-box {
    padding: 0px 10px;
    border-radius: 20px;
    display: inline-block;
    border: 1px solid #46bfea;
    color: #46bfea;
    margin: 5px;
  }
}
.status {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  background: #2791f7;
  border-radius: 20px;
  border: 1px solid #2791f7;
  color: #fff;
}
.finish {
  background: #e19b39;
  border: 1px solid #e19b39;
}
.ongoing {
  background: #69c43b;
  border: 1px solid #69c43b;
}
</style>
