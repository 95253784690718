var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"sectionWrapper",staticClass:"content-view section-wrapper",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('table-title',{on:{"resize":_vm.handleTableTitleResize},scopedSlots:_vm._u([{key:"operateAction",fn:function(){return [_c('i',{staticClass:"e6-icon-history",attrs:{"title":"历史记录"},on:{"click":_vm.toRecord}}),_vm._v(" "),_c('i',{staticClass:"e6-icon-export_line",attrs:{"title":"导出"},on:{"click":_vm.handleExport}})]},proxy:true},{key:"searchForm",fn:function(){return [_c('el-form',{ref:"searchForm",staticClass:"search-list",attrs:{"model":_vm.searchForm}},[_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"itemId"}},[_c('el-input',{attrs:{"placeholder":"工单ID","title":"工单ID","maxlength":"10"},on:{"input":function($event){_vm.searchForm.itemId = _vm.searchForm.itemId.replace(/[^0-9-]+/, '')}},model:{value:(_vm.searchForm.itemId),callback:function ($$v) {_vm.$set(_vm.searchForm, "itemId", $$v)},expression:"searchForm.itemId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"itemStatus"}},[_c('e6-vr-select',{attrs:{"data":_vm.itemStatusList,"placeholder":"任务项状态","title":"任务项状态","clearable":"","props":{
                id: 'codeValue',
                label: 'codeName'
              }},model:{value:(_vm.searchForm.itemStatus),callback:function ($$v) {_vm.$set(_vm.searchForm, "itemStatus", $$v)},expression:"searchForm.itemStatus"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"sceneName"}},[_c('el-input',{attrs:{"placeholder":"场景名称","title":"场景名称"},model:{value:(_vm.searchForm.sceneName),callback:function ($$v) {_vm.$set(_vm.searchForm, "sceneName", $$v)},expression:"searchForm.sceneName"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"sceneType"}},[_c('e6-vr-select',{attrs:{"data":_vm.sceneTypeList,"placeholder":"场景类型","title":"场景类型","clearable":"","props":{
                id: 'codeValue',
                label: 'codeName'
              }},model:{value:(_vm.searchForm.sceneType),callback:function ($$v) {_vm.$set(_vm.searchForm, "sceneType", $$v)},expression:"searchForm.sceneType"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"taskSecondClass"}},[_c('e6-vr-select',{attrs:{"data":_vm.taskTypeList,"placeholder":"任务单类型","title":"任务单类型","multiple":"","clearable":"","props":{
                id: 'codeValue',
                label: 'codeName'
              }},model:{value:(_vm.searchForm.taskSecondClass),callback:function ($$v) {_vm.$set(_vm.searchForm, "taskSecondClass", $$v)},expression:"searchForm.taskSecondClass"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"workNo"}},[_c('el-input',{attrs:{"placeholder":"所属任务单号","title":"所属任务单号"},model:{value:(_vm.searchForm.workNo),callback:function ($$v) {_vm.$set(_vm.searchForm, "workNo", $$v)},expression:"searchForm.workNo"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"scheduleTaskId"}},[_c('el-input',{attrs:{"placeholder":"所属调度任务ID","title":"所属调度任务ID"},on:{"input":function($event){_vm.searchForm.scheduleTaskId = _vm.searchForm.scheduleTaskId.replace(
                  /[^0-9-]+/,
                  ''
                )}},model:{value:(_vm.searchForm.scheduleTaskId),callback:function ($$v) {_vm.$set(_vm.searchForm, "scheduleTaskId", $$v)},expression:"searchForm.scheduleTaskId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"scheduleInfoId"}},[_c('el-input',{attrs:{"placeholder":"所属调度信息ID","title":"所属调度信息ID"},on:{"input":function($event){_vm.searchForm.scheduleInfoId = _vm.searchForm.scheduleInfoId.replace(
                  /[^0-9-]+/,
                  ''
                )}},model:{value:(_vm.searchForm.scheduleInfoId),callback:function ($$v) {_vm.$set(_vm.searchForm, "scheduleInfoId", $$v)},expression:"searchForm.scheduleInfoId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"engineerrpid"}},[_c('e6-vr-select',{attrs:{"data":_vm.engineerList,"placeholder":"工程师","title":"工程师","clearable":"","props":{
                id: 'employeeId',
                label: 'employeeName'
              }},model:{value:(_vm.searchForm.engineerrpid),callback:function ($$v) {_vm.$set(_vm.searchForm, "engineerrpid", $$v)},expression:"searchForm.engineerrpid"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"engineerrpPhone"}},[_c('el-input',{attrs:{"placeholder":"工程师电话","title":"工程师电话"},model:{value:(_vm.searchForm.engineerrpPhone),callback:function ($$v) {_vm.$set(_vm.searchForm, "engineerrpPhone", $$v)},expression:"searchForm.engineerrpPhone"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpName"}},[_c('input-select',{attrs:{"data":_vm.corpList,"placeholder":"客户名称","title":"客户名称","clearable":"","virtual":"","remote":"","is-title":true,"props":{
                id: 'corpName',
                label: 'corpName'
              }},on:{"filterChange":_vm.handleLoadCorpFilter},model:{value:(_vm.searchForm.corpName),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpName", $$v)},expression:"searchForm.corpName"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--2",attrs:{"prop":"finishTime"}},[_c('e6-date-picker',{ref:"effectiveDatePicker",attrs:{"popper-class":"no-atTheMoment","label":"完成时间","title":"完成时间","picker-options":_vm.pickerOptions('searchForm.finishTime'),"default-time":['00:00:00', '23:59:59'],"start-placeholder":"完成时间（始）","end-placeholder":"完成时间（止）"},model:{value:(_vm.searchForm.finishTime),callback:function ($$v) {_vm.$set(_vm.searchForm, "finishTime", $$v)},expression:"searchForm.finishTime"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--buttons"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_vm._v(" "),_c('el-button',{staticClass:"reset",on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)]},proxy:true}])}),_vm._v(" "),_c('section',{staticClass:"table-wrapper"},[_c('el-table',{ref:"elTable",staticClass:"elTable",attrs:{"border":"","height":_vm.resizeViewHeight + 'px',"data":_vm.tableData,"highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"序号","width":"50","fixed":"left","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v("\n              "+_vm._s(scope.$index +
                  1 +
                  (_vm.searchForm.pageIndex - 1) * _vm.searchForm.pageSize)+"\n            ")])]}}])}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [(column.fieldName === 'label')?_c('div',{staticClass:"table-label"},_vm._l((row.label),function(item,index){return _c('span',{key:index,staticClass:"label-box"},[_vm._v(_vm._s(item))])}),0):(column.fieldName === 'status')?_c('span',{class:[
                'status',
                row.status === 1
                  ? 'finish'
                  : row.status === 2
                  ? 'ongoing'
                  : ''
              ]},[_vm._v("\n              已完成\n            ")]):(column.fieldName === 'workNo')?_c('span',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleGoToDetail(row)}}},[_vm._v("\n                "+_vm._s(row[column.fieldName])+"\n              ")])],1):(column.fieldName === 'scheduleId')?_c('span',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toSchedule(row)}}},[_vm._v("\n                "+_vm._s(row[column.fieldName])+"\n              ")])],1):(column.fieldName === 'scheduleInfoId')?_c('span',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toScheduleInfoDetail(row)}}},[_vm._v("\n                "+_vm._s(row[column.fieldName])+"\n              ")])],1):_c('span',[_vm._v(_vm._s(row[column.fieldName]))])]}}],null,true)})}),_vm._v(" "),_c('el-table-column',{attrs:{"fixed":"right","align":"center","label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('e6-td-operate',{attrs:{"data":_vm.getOperateList()},on:{"command":function($event){return _vm.handleOperate($event, scope.row)}}})]}}])})],2)],1),_vm._v(" "),_c('section',{ref:"paginationWrapper",staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }